<script>
  /**
   * Toggle Switch
   */

  export default {
    name: 'toggle-switch',
    props: {
      value: {
        type: Boolean,
      },
      onChange: {
        type: Function,
      },
    },
    computed: {
      state() {
        return !!this.value;
      },
    },
    methods: {
      change(e) {
        this.onChange(!this.value);
      },
    },
  };
</script>

<template>
  <label class="toggle-switch">
    <div class="toggle-switch__inner">
      <input 
        @change="change"
        type="checkbox"
        class="toggle-switch__input"
        :value="value"
        :checked="state"
      />
      <span class="toggle-switch__circle"></span>
      <span class="toggle-switch__label toggle-switch__label--on">Yes</span>
      <span class="toggle-switch__label toggle-switch__label--off">No</span>
    </div>
  </label>
</template>