
/*
|--------------------------------------------------------------------------
| Dependency Definitions
|--------------------------------------------------------------------------
*/

require('./bootstrap');

import Vue from 'vue';



/*
|--------------------------------------------------------------------------
| Global Components
|--------------------------------------------------------------------------
*/

Vue.component('main-tour-search', require('./components/MainTourSearch').default);
Vue.component('like-button', require('./components/LikeButton').default);



/*
|--------------------------------------------------------------------------
| Vue Instance
|--------------------------------------------------------------------------
*/

const app = new Vue({
  el: '#app-root',

  methods: {
    goToURL(url) {
      window.location = url;
    }
  }
});
