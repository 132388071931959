<script>
  /**
   * Dropdown List
   * 
   * Used in the dropdown
   */

  export default {
    name: 'dropdown-list',
    props: {
      items: { // Array of objects
        type: Array,
      },
      activeItem: {
        type: Object,
      },
      onItemClick: { // Runs on each item click
        type: Function,
      },
    },
    methods: {
      click(item) {
        if (typeof item.onClick === 'function') {
          item.onClick(item);
        }

        if (typeof this.onItemClick === 'function') {
          this.onItemClick(item);
        }
      }
    }
  };
</script>

<template>
    <div class="list-group">
      <button 
        v-for="(item, index) in items" 
        :class="['list-group-item list-group-item-action', { active: activeItem && (activeItem.label === item.label) }, $style['dropdown-list__item']]" 
        :key="index" 
        type="button"
        @click="() => click(item)"
      >
        {{item.label}}
      </button>
    </div>
</template>

<style module lang="scss">
  .dropdown-list {

  }

  /**
   * Item
   **/
  
  .dropdown-list__item {
    cursor: pointer;
    outline: 0 !important;
  }

  /**
   * Button
   **/

  .dropdown-list__item__button {
    
  }

</style>