<script>
  /**
   * Flight Search Form -> Dropdown
   */

  export default {
    name: 'dropdown',
    props: {
      label: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      open: {
        type: Boolean,
      },
      onToggle: {
        type: Function,
        required: true,
      },
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside, true);
    },
    watch: {
      open(val) {
        if (val) {
          // Detect all clicks on the document
          document.addEventListener('click', this.handleClickOutside, true);
        } else {
          document.removeEventListener('click', this.handleClickOutside, true);
        }        
      }
    },
    methods: {
      // Toggle
      toggleDropdown(e) {
        e.preventDefault();
        
        // Passed as prop
        this.onToggle();
      },
      // Handle click outside
      handleClickOutside(e) {
        // If user clicks on the button or in the dropdown
        // We don't want to do anything
        // if (e.target.closest(`.${this.$style.button}`) || e.target.closest(`.${this.$style.dropdown}`)) {
        //   return;
        // // If the dropdown is open - close it
        // } else if (this.open) {
        //   this.onToggle();
        //   document.removeEventListener('click', this.handleClickOutside, true);
        // }
      }
    }
  }
</script>

<template>
  <div class="ui-dropdown">
    <button 
      type="button" 
      :class="['ui-dropdown__button', 'btn btn-primary', { 'has-placeholder': !label }]"
      :aria-label="label" 
      @click="toggleDropdown"
    >
      <span class="ui-dropdown__button__icon">
        <slot name="icon" />
      </span>
      <span class="ui-dropdown__button__label">
          {{label || placeholder}}
      </span>
      <span :class="['ui-dropdown__button__triangle', { 'button__triangle--flipped': open }]">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7"><path d="M10.4.7c-.2-.1-.4-.2-.7-.2H1.3c-.6 0-1 .4-1 1 0 .3.1.5.3.7L4 5.9c.8.8 2 .9 2.8.1l.1-.1 3.5-3.7c.4-.5.4-1.1 0-1.5z" /></svg>
      </span>
    </button>
    <div class="ui-dropdown__content" v-if="open">
      <slot></slot>
    </div>
  </div>
</template>