<script>
  /**
   * Dropdown: Departure
   */

  import Vue from 'vue';
  import Dropdown from '../Dropdown';
  import MonthSelection from './components/MonthSelection';
  import DatetimeCalendar from 'vue-datetime/src/DatetimeCalendar';
  import 'vue-datetime/dist/vue-datetime.min.css'
  import DateTime from 'luxon/src/datetime.js'

  export default {
    name: 'dropdown-date-picker',
    components: {
      Dropdown,
      calendar: DatetimeCalendar,
      MonthSelection,
    },
    props: {
      onSelect: {
        type: Function,
        required: true,
      },
      onClear: {
        type: Function,
        required: true,
      },
      value: {
        type: Object,
      },
    },
    data() {
      return {
        open: false,
        currentTab: 'months',
      };
    },
    computed: {
      currentDate() {
        const currentDate = new Date();

        return {
          day: currentDate.getUTCDate(),
          month: currentDate.getMonth() + 1, // January = 0
          year: currentDate.getFullYear(),
        };
      },
      hasSelectedDate() {
        return this.day && this.month && this.year;
      },
      label() {
        return this.value.label;
      },
      minDate() {
        var currentDate = new Date();

        // if (this.customMinDate != null) {
        //   var parts = this.customMinDate.split('-');
        //   currentDate = new Date(parts[0], parts[1] - 1, parts[2]);
        // }

        // console.log("Custom min date: ", DateTime.fromISO(currentDate.toISOString()));

        return DateTime.fromISO(currentDate.toISOString());
      }
    },
    methods: {
      toggle() {
        this.open = !this.open;
      },
      onItemClick(item) {
        this.close();
        this.selected = item;
        this.onSelectItem(item);
      },
      selectDate(year, month, day) {
        this.year = year;
        this.month = month;
        this.day = day;

        if (typeof this.onSelect === 'function') {
          this.onSelect('date', { year, month, day });
        }

        this.close();
      },
      selectMonth(key, value) {
        this.onSelect('month', { key, ...value });

        this.close();
      },
      selectMonthYear(year) {
        this.onSelect('month_year', year);
      },
      close() {
        this.open = false;
      },
      clear() {
        this.onClear();
        this.close();
      },
    }
  };
</script>

<template>
  <dropdown placeholder="Select date" :label="label" :open="open" :onToggle="toggle">
    <svg slot="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M36.9 9.1h-1v-1c0-1.7-1.4-3.1-3.1-3.1s-3.1 1.4-3.1 3.1v1h-4.1v-1c0-1.7-1.4-3.1-3.1-3.1s-3.1 1.4-3.1 3.1v1h-4.2v-1c0-1.7-1.4-3.1-3.1-3.1s-3 1.4-3 3.1v1h-1C6.4 9.1 5 10.5 5 12.2v24.7C5 38.6 6.4 40 8.1 40h28.8c1.7 0 3.1-1.4 3.1-3.1V12.2c0-1.7-1.4-3.1-3.1-3.1zm-5.1-1c0-.6.5-1 1-1s1 .5 1 1v4.1c0 .6-.5 1-1 1s-1-.5-1-1V8.1zm-10.3 0c0-.6.5-1 1-1s1 .5 1 1v4.1c0 .6-.5 1-1 1s-1-.5-1-1V8.1zm-10.3 0c0-.6.5-1 1-1s1 .5 1 1v4.1c0 .6-.5 1-1 1s-1-.5-1-1V8.1zm26.7 28.8c0 .6-.5 1-1 1H8.1c-.6 0-1-.5-1-1V19.5H38l-.1 17.4zm0-19.5H7.1v-5.3c0-.6.5-1 1-1h1v1c0 1.7 1.4 3.1 3.1 3.1s3.1-1.4 3.1-3.1v-1h4.2v1c0 1.7 1.4 3.1 3.1 3.1s3.1-1.4 3.1-3.1v-1h4.1v1c0 1.7 1.4 3.1 3.1 3.1s3.1-1.4 3.1-3.1v-1h1c.6 0 1 .5 1 1l-.1 5.3z"/><path d="M34.9 21.5H10.1c-.6 0-1 .5-1 1v12.3c0 .6.5 1 1 1h24.7c.6 0 1-.5 1-1V22.6c.1-.6-.4-1.1-.9-1.1zM15.3 33.8h-4.1v-4.1h4.1v4.1zm0-6.1h-4.1v-4.1h4.1v4.1zm6.2 6.1h-4.2v-4.1h4.2v4.1zm0-6.1h-4.2v-4.1h4.2v4.1zm6.2 6.1h-4.1v-4.1h4.1v4.1zm0-6.1h-4.1v-4.1h4.1v4.1zm6.1 6.1h-4.1v-4.1h4.1v4.1zm0-6.1h-4.1v-4.1h4.1v4.1z"/></svg>
    <slot>
      <div :class="$style['dropdown-content']">
        <div :class="$style['dropdown-content__header']">
          <div :class="['btn-group', $style['dropdown-content__header__button-group']]" role="group">
            <button
              v-for="button in [
                {
                  type: 'months',
                  label: 'Month',
                },
                {
                  type: 'date',
                  label: 'Explicit date',
                },
              ]"
              :key="button.type"
              type="button"
              @click="currentTab = button.type"
              :class="['btn', button.type === currentTab ? 'btn-primary' : 'btn-light']">
              {{ button.label }}
            </button>
          </div>
        </div>
        <div :class="$style['dropdown-content__months']" v-if="currentTab === 'months'">
          <month-selection
            :selectedMonths="value.months"
            :selectedYear="value.month_year"
            :onSelectMonth="selectMonth"
            :onSelectYear="selectMonthYear"
          />
        </div>
        <div :class="$style['dropdown-content__datepicker']" v-if="currentTab === 'date'">
          <calendar
            :month="value.date.month"
            :year="value.date.year"
            :day="value.date.day"
            @change="selectDate"
            :minDate="minDate"
          ></calendar>
        </div>
        <div :class="$style['dropdown-content__footer']">
          <button type="button" @click="clear" class="btn btn-sm btn-light">Clear</button>
        </div>
      </div>

    </slot>
  </dropdown>
</template>

<style lang="scss" module>
  @import "resources/assets/sass/base/variables";

  .dropdown-content {
    width: 100%;
  }

  /**
   * Header
   **/

  .dropdown-content__header__button-group {
    width: 100%;
    border: 0;
    margin: 0;
    height: 44px;

    :global(.btn) {
      flex: 50%;
      flex-grow: 1;
      flex-shrink: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 2px solid $primary;
      box-shadow: none !important;
    }
  }

  /**
   * Footer
   **/
  .dropdown-content__footer {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  /**
   * Months
   **/

  .dropdown-content__months {
    padding: 10px;
  }

  /**
   * Date Picker
   **/
  .dropdown-content__datepicker {

    padding: 5px 15px;

    // Custom changes to v-datetime-calendar
    :global(.vdatetime-calendar__month) {
      padding: 0;
      font-size: 14px;
    }
    :global(.vdatetime-calendar__month__day) {
      font-size: 14px;

      span > span {
        border-radius: 4px;
      }
    }

    :global(.vdatetime-calendar__month__day--selected) {
      > span > span {
        background-color: $primary !important;
      }
    }
  }
</style>