<script>
  /*
  |--------------------------------------------------------------------------
  | Like Button
  |--------------------------------------------------------------------------
  */

  export default {
    name: 'like-button',
    data() {
      return {
        likedValue: this.liked,
      };
    },
    props: ['liked', 'tourid', 'userid'],
    computed: {
      classObject: function () {
        return {
          'like_button_liked': this.likedValue,
          'like_button': !this.likedValue
        }
      }
    },
    methods: {
      toggleLike() {
        var self = this;

        this.likedValue = !this.likedValue;

        if (this.likedValue) {
          axios.post('/api/v1/tour/like', {
            tour_id: self.tourid,
            user_id: self.userid
          })
          .then(function (response) {
          })
          .catch(function (error) {
            console.log("Error liking tour");
          });
        } else {
          axios.post('/api/v1/tour/unlike', {
          tour_id: self.tourid,
          user_id: self.userid
        })
        .then(function (response) {
        })
        .catch(function (error) {
          console.log("Error liking tour");
        });
        }
      }
    }
  };
</script>

<template>
  <div v-on:click.stop="toggleLike" v-bind:class="classObject"></div>
</template>

<style module lang="scss">
</style>