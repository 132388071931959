<script>

import DropdownDestination from './components/DropdownDestination';
import DropdownTripType from './components/DropdownTripType';
import DropdownDeparture from './components/DropdownDeparture';
import ToggleSwitch from './components/ToggleSwitch';

const currentDate = new Date();

// We store the initial departure values here
// so that we can clear/reset them later on
const initialDepartureValues = () => ({
  label: 'Anytime',
  type: 'all',
  months: {},
  month_year: currentDate.getFullYear().toString(),
  date: {
    day: currentDate.getUTCDate(),
    month: currentDate.getMonth() + 1, // January = 0
    year: currentDate.getFullYear(),
  },
});

export default {
  name: 'main-tour-search',

  components: {
    DropdownDestination,
    DropdownTripType,
    DropdownDeparture,
    ToggleSwitch
  },

  props: [
    'user',
    'strings',
    'language'
  ],

  data() {
    return {
      submitting: false,

      values: {
        to: {
          label: this.strings.search_form.js_components_flights_search_form_anywhere_label,
          slug: 'anywhere',
        },

        type: {
          label: this.strings.search_form.js_components_flights_search_form_whatever_label,
          slug: 'whatever'
        },

        departure_date: initialDepartureValues(),
      },
    };
  },

  computed: {
    getFirstMonth() {
      return this.values.departure_date.months[Object.keys(this.values.departure_date.months)[0]];
    },
  },

  created() {
  },

  methods: {
    updateValue(key, value) {
      this.values[key] = value;
    },

    updateDepartureDate(type, data) {
      this.values.departure_date.type = type;

      // Date selection
      if (type === 'date') {
        this.values.departure_date.date = data;

        var currentMonth = `${data.month}`;
        if (data.month < 10) {
          currentMonth = '0' + currentMonth;
        }

        var currentDay = `${data.day}`;
        if (data.day < 10) {
          currentDay = '0' + currentDay;
        }

        this.values.departure_date.label = `${data.year}-${currentMonth}-${currentDay}`;
      }

      // Month selection
      if (type === 'month') {
        // Currently we are overwriting the entire array
        // because it's only possible to choose one month for now
        // Update this to add/remove from an array later on
        console.log('month', data);
        this.values.departure_date.months = { [data.key]: data };
        this.values.departure_date.label = `${data.month_name_long} ${this.values.departure_date.month_year}`;
      }

      // Year selection (month)
      if (type === 'month_year') {
        this.values.departure_date.month_year = data;

        // Only update label if month is already selected
        if (Object.keys(this.values.departure_date.months).length) {
          const month = this.getFirstMonth.month_name_long;
          console.log('month', month);
          this.values.departure_date.label = `${month} ${data}`;
        }
      }
    },

    // Clear departure form (reset)
    onClearDeparture() {
      this.values.departure_date = initialDepartureValues();
    },

    // Submit form
    onSubmit(e) {
      e.preventDefault();

      const { to, type, departure_date } = this.values;
      let departure = 'anytime';
      let departureAirport = '';

      // Departure date is month/year
      if (departure_date.type === 'month') {
        var currentMonth = `${this.getFirstMonth.key}`;
        if (currentMonth < 10) {
          currentMonth = '0' + currentMonth;
        }

        departure = `${departure_date.month_year}-${currentMonth}`;
      }

      // Departure date is an explicit date
      if (departure_date.type === 'date') {
        var currentMonth = `${departure_date.date.month}`;
        if (departure_date.date.month < 10) {
          currentMonth = '0' + currentMonth;
        }

        var currentDay = `${departure_date.date.day}`;
        if (departure_date.date.day < 10) {
          currentDay = '0' + currentDay;
        }

        departure = `${departure_date.date.year}-${currentMonth}-${currentDay}`;
      }

      // Build URL
      var url = '';
      if (this.language == 'en') {
        url = `/destination/${to.slug}?lookingfor=${type.slug}&departure=${departure}`;
      } else {
        url = `/${this.language}/destination/${to.slug}?lookingfor=${type.slug}&departure=${departure}`;
      }

      // Go to URL
      this.submitting = true;
      window.location = url;
    }
  }
};
</script>

<template>
  <form :class="$style['main-tour-search']" @submit="onSubmit">
    <fieldset :class="['form-group', $style['main-tour-search__fieldset']]">
      <label :class="$style['main-tour-search__label']">{{ strings.search_form.js_components_flights_search_form_going_to_label }}</label>
      <dropdown-destination
      :language="language"
      :value="values.to"
      :onSelectItem="(item) => updateValue('to', item)" />
    </fieldset>
    <fieldset :class="['form-group', $style['main-tour-search__fieldset']]">
      <label :class="$style['main-tour-search__label']">{{ strings.search_form.js_components_flights_search_form_looking_for_label }}</label>
      <dropdown-trip-type
      :value="values.type"
      :onSelectItem="(item) => updateValue('type', item)"
      :strings="strings" />
    </fieldset>
    <fieldset :class="['form-group', $style['main-tour-search__fieldset']]">
      <label :class="$style['main-tour-search__label']">{{ strings.search_form.js_components_flights_search_form_departure_label }}</label>
      <dropdown-departure
      :value="values.departure_date"
      :onSelect="updateDepartureDate"
      :onClear="onClearDeparture"
      />
    </fieldset>

    <fieldset :class="['form-group', $style['main-tour-search__fieldset'], $style['main-tour-search__fieldset--button']]">
      <label :class="$style['main-tour-search__label']"></label>
      <button :class="['btn btn-primary', $style['main-tour-search__button']]" :disabled="submitting">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21"><path d="M19.4 18.68l-4.6-4.6c1.1-1.4 1.8-3.2 1.8-5.1 0-4.5-3.6-8.1-8.1-8.1S.4 4.48.4 8.98s3.6 8.1 8.1 8.1c1.9 0 3.7-.7 5.1-1.8l4.6 4.6c.3.3.8.3 1.1 0 .4-.3.4-.9.1-1.2zm-10.9-3.2c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5 6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5z"/></svg>
        {{ strings.search_form.js_components_flights_search_form_go_button }}
      </button>
    </fieldset>
  </form>
</template>

<style module lang="scss">
  .main-tour-search {
    max-width: 1150px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
  }

  .main-tour-search__fieldset {
    margin-bottom: 0;
    flex: 1;
    padding-right: 20px;
    flex-shrink: 0;

    &:last-child {
      padding-right: 0;
    }
  }

  .main-tour-search__fieldset--button {
    flex: 125px;
    flex-grow: 0;
  }

  label.main-tour-search__label {
    display: block;
    min-height: 14px;
    font-size: 13px;
    font-weight: 400;
  }

  .main-tour-search__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    justify-content: center;
    font-size: 18px;
    line-height: 100%;
    text-transform: uppercase;

    svg {
      width: 18px;
      height: 18px;
      fill: #fff;
      margin-right: 10px;
    }
  }

/**
* Responsive
**/

@media screen and (max-width: 1150px) {
  .main-tour-search {
    // flex-direction: column;
    max-width: 500px;
    flex-wrap: wrap;

    .main-tour-search__fieldset {
      flex: 100%;
      margin-bottom: 20px;
      padding-right: 0;

      &.main-tour-search__fieldset--button {
        flex: 150px;
        margin-left: auto;
        flex-grow: 0;
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>