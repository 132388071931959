<script>
  /**
   * Dropdown: Looking for..
   */

  import Dropdown from '../Dropdown';
  import DropdownList from '../DropdownList';

  export default {
    name: 'dropdown-trip-type',
    components: {
      Dropdown,
      DropdownList,
    },
    props: {
      onSelectItem: {
        type: Function,
        required: true,
      },
      value: {
        required: true,
      },
      strings: {
        required: true,
      }
    },
    data() {
      return {
        open: false,
        listItems: [
          {
            label: this.strings.dropdown_trip_type.js_components_flights_search_form_dropdown_trip_type_whatever_label,
            slug: 'whatever',
          },
          {
            label: this.strings.dropdown_trip_type.js_components_flights_search_form_dropdown_trip_type_guided_tours_label,
            slug: 'guided-tours',
          },
          {
            label: this.strings.dropdown_trip_type.js_components_flights_search_form_dropdown_trip_type_day_excursions_label,
            slug: 'excursions',
          },
          {
            label: this.strings.dropdown_trip_type.js_components_flights_search_form_dropdown_trip_type_car_tours_label,
            slug: 'car',
          },
          {
            label: this.strings.dropdown_trip_type.js_components_flights_search_form_dropdown_trip_type_city_break_tours_label,
            slug: 'city-break',
          },
          // {
          //   label: this.strings.dropdown_trip_type.js_components_flights_search_form_dropdown_trip_type_flights_only_label,
          //   slug: 'flights-only',
          // },
          {
            label: this.strings.dropdown_trip_type.js_components_flights_search_form_dropdown_trip_type_more_than_nine_label,
            slug: 'company-trips',
          },
          {
            label: this.strings.dropdown_trip_type.js_components_flights_search_form_dropdown_trip_type_group_travel_label,
            slug: 'student-trips',
          },
        ]
      };
    },
    methods: {
      toggle() {
        this.open = !this.open;
      },
      onItemClick(item) {
        this.open = false;
        this.onSelectItem(item);
      },
    }
  };
</script>

<template>
  <dropdown :label="value && value.label" placeholder="Choose" :open="open" :onToggle="toggle">
    <svg slot="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M34.2 6.7l-12-3.4h-.4l-12 3.4c-.5.1-.6.4-.6.7v32.5c0 .3.1.4.3.7.2.2.4.2.8.2l11.8-3.3 11.7 3.3h.2c.2 0 .4-.1.4-.2.2-.2.3-.4.3-.7V7.4c.1-.3-.3-.6-.5-.7zM21.1 35.9l-10.2 2.9V8.1l10.3-2.9v30.7h-.1zm12 2.9l-10.3-3V5.2l10.3 2.9v30.7z"/><path d="M25.1 10.1l5.1 1.4h.2c.4 0 .7-.2.8-.6.1-.4-.1-.9-.6-1.1l-5.1-1.4c-.4-.1-.9.1-1.1.6 0 .6.3.9.7 1.1zm0 4.2l5.1 1.4h.2c.4 0 .7-.2.8-.6.1-.4-.1-.9-.6-1.1l-5.1-1.4c-.4-.1-.9.1-1.1.6 0 .6.3 1 .7 1.1zm0 4.4l5.1 1.4h.2c.4 0 .7-.2.8-.6.1-.4-.1-.9-.6-1.1l-5-1.4c-.4-.1-.9.1-1.1.6-.1.4.2.8.6 1.1zm0 15.4l5.1 1.4h.2c.2 0 .4-.1.4-.2.2-.2.3-.4.3-.7V23.4c0-.4-.3-.7-.6-.8l-5.1-1.4c-.3-.1-.4 0-.8.1-.2.2-.3.4-.3.7v11.2c.3.5.5.8.8.9zm1.1-10.9l3.4 1v9.4l-3.4-1v-9.4zm-7.9 9.2l-5.2 1.4c-.4.1-.7.6-.6 1.1.1.4.4.6.8.6h.2l5.1-1.4c.4-.1.7-.6.6-1.1 0-.4-.4-.7-.9-.6zm-4.9-20.7h.2l5.1-1.4c.4-.1.7-.6.6-1.1s-.6-.7-1.1-.6l-5.1 1.3c-.4.1-.7.6-.6 1.1.3.3.5.7.9.7zm4.9 1l-5.1 1.6c-.4.1-.7.6-.6 1.1.1.4.4.6.8.6h.2l5.1-1.4c.4-.1.7-.6.6-1.1-.1-.7-.5-.9-1-.8z"/></svg>
    <slot>
      <dropdown-list
        :items="listItems"
        :onItemClick="onItemClick"
        :activeItem="value"
      />
    </slot>
  </dropdown>
</template>