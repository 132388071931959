<script>
  /**
   * Dropdown: Going to (country selctor)
   */

  import axios from 'axios';
  import Dropdown from '../Dropdown';
  import DropdownList from '../DropdownList';

  export default {
    name: 'dropdown-destination',
    components: {
      Dropdown,
      DropdownList,
    },
    mounted() {
      this.fetchDestinations();
    },
    props: {
      onSelectItem: {
        type: Function,
        required: true,
      },
      value: {
        required: true,
      },
      language: {
        required: true,
      },
    },
    data() {
      return {
        open: false,
        listItems: [
          {
            label: 'Anywhere',
          },
        ]
      };
    },
    methods: {
      fetchDestinations() {
        axios.get('/api/v1/destinations').then(response => {
          this.listItems = response.data.data.map(({ title_en, title_da, id, slug_en, slug_da }) => ({
            label: this.localizedDestination(title_en, title_da),
            id,
            slug: this.localizedDestination(slug_en, slug_da)
          }));

          // DA filtering
          if (this.language == 'da') {
            var allDestinations = response.data.data.map(({ title_en, title_da, id, slug_en, slug_da }) => ({
              label: this.localizedDestination(title_en, title_da),
              id,
              slug: this.localizedDestination(slug_en, slug_da)
            }));

            var items = [];

            for (var i = 0; i < allDestinations.length; i++) {
              let currentDestination = allDestinations[i];

              if (currentDestination.label == 'Hvor som helst' || currentDestination.label == 'Island' || currentDestination.label == 'Færøerne' || currentDestination.label == 'Irland' || currentDestination.label == 'England' || currentDestination.label == 'Skotland') {
                items.push(currentDestination);
              }
            }

            this.listItems = items;
          }
        }).catch((error) => {
          console.error('Error fetching destinations:', error);
        });
      },
      toggle() {
        this.open = !this.open;
      },
      onItemClick(item) {
        this.open = false;
        this.onSelectItem(item);
      },
      localizedDestination(english, danish) {
        if (this.language == 'da') {
          if (danish == null || danish == '') {
            return english;
          }

          return danish;
        }

        return english;
      },
    }
  };
</script>

<template>
  <dropdown :label="value && value.label" placeholder="Choose" :open="open" :onToggle="toggle">
      <svg slot="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><path d="M4.2 37.5h36v1.9h-36v-1.9zm35.5-24.7c-2.6-1.5-5.6-.7-7.6.2L26 15.9l-10.4-5.3-6.2.3 8.6 8.7-5.5 2.6L6.8 20 3 21.8l3.4 4.3c-.4.4-.7 1-.4 1.7.4.8 1.5 1.3 3.4 1.3.4 0 .8 0 1.3-.1 2.1-.2 4.3-.7 5.8-1.4l21.7-10c1.9-.9 2.8-1.8 2.8-2.8 0-.6-.2-1.3-1.3-2zm-2.2 3.3L15.9 26.2c-1.2.6-3.2 1-5 1.2-1.9.2-2.9 0-3.2-.2.1-.1.2-.2.4-.5l.6-.5-3.1-3.9 1.4-.7 5.7 2.2 8.1-3.8-7.8-7.7 2.3-.1L26 17.6l6.7-3.1c2.5-1.1 4.5-1.2 6.2-.3.4.2.6.4.6.5-.1 0-.1.6-2 1.4z"/></svg>
    <slot>
      <dropdown-list
        :items="listItems"
        :onItemClick="onItemClick"
        :activeItem="value"
      />
    </slot>
  </dropdown>
</template>