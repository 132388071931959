<script>
  /**
   * Month selection
   */

  export default {
    name: 'month-selection',
    props: {
      onSelectMonth: {
        type: Function,
        required: true,
      },
      onSelectYear: {
        type: Function,
        required: true,
      },
      selectedMonths: {
        type: Object,
        required: true,
      },
      selectedYear: {
        type: String,
      },
    },
    computed: {
      years() {
        const now = new Date();
        const currentYear = now.getFullYear();
        let years = [];
        let count = 0;

        while (years.length < 6) {
          years.push((currentYear + count).toString());
          count++;
        }

        return years;
      },
      months() {
        return {
          1: {
            month_name_short: 'jan',
            month_name_long: 'January',
          },
          2: {
            month_name_short: 'feb',
            month_name_long: 'February'
          },
          3: {
            month_name_short: 'mar',
            month_name_long: 'March'
          },
          4: {
            month_name_short: 'apr',
            month_name_long: 'April'
          },
          5: {
            month_name_short: 'may',
            month_name_long: 'May'
          },
          6: {
            month_name_short: 'jun',
            month_name_long: 'June'
          },
          7: {
            month_name_short: 'jul',
            month_name_long: 'July'
          },
          8: {
            month_name_short: 'aug',
            month_name_long: 'August'
          },
          9: {
            month_name_short: 'sep',
            month_name_long: 'September'
          },
          10: {
            month_name_short: 'oct',
            month_name_long: 'October'
          },
          11: {
            month_name_short: 'nov',
            month_name_long: 'November'
          },
          12: {
            month_name_short: 'dec',
            month_name_long: 'December'
          },
        };
      },
    },
    methods: {
      isSelected(key) {
        return typeof this.selectedMonths[key] !== 'undefined';
      },
      select(key, value) {
        this.onSelectMonth(key, value);
      },
      onYearChange(e) {
        this.onSelectYear(e.target.value);
      },
    }
  }
</script>

<template>
  <div :class="$style['month-selection']">
    <select @change="onYearChange" :class="['form-control', $style['month-selection__year-dropdown']]">
      <option v-for="year in years" :key="year" :selected="year === selectedYear">
        {{year}}
      </option>
    </select>
    <ul :class="$style['month-selection__list']">
      <li v-for="(value, key) in months" :key="key" :class="$style['month-selection__list__item']">
        <button 
          type="button"
          :class="['btn', $style['month-selection__button'], isSelected(key) ? 'btn-primary' : 'btn-light']"
          :title="value.month_name_long"
          @click="() => select(key, value)"
        >
          {{value.month_name_short}}
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" module>
  .month-selection__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .month-selection__year-dropdown {
    margin-bottom: 10px;
  }

  .month-selection__button {
    text-transform: uppercase;
  }

  .month-selection__list__item {
    flex: 25%;
    flex-shrink: 0;
    flex-grow: 0;

    :global(.btn) {
      width: 100%;
      height: 44px;
    }
  }
</style>